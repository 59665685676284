import { marketPlace } from '@/axios'

export default {
  addBranch (payload) {
    return marketPlace().post('merchant/branches', payload)
  },
  getBranch (id) {
    return marketPlace().get(`merchant/branches/${id}`)
  },
  editBranch (id, payload) {
    return marketPlace().patch(`merchant/branches/${id}`, payload)
  }
}
