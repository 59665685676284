<template>
  <div>
    <dashboard-page-title
      :showSearch="false"
      :showFilter="false"
      :showMainActions="false"
      @addEvent="$router.push({ name: 'addMerchant' })"
    >
      {{ $t("main.branches") }}
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div>
          <b-card class="iq-border-radius-10 mb-3">
            <template v-slot:header>
              <h5 class="text-primary">
                <i class="las la-file-alt"></i>
                {{ $t("main.branch") }}
              </h5>
            </template>
            <b-row>
              <b-col md="12" class="mb-3">
                <input-form
                  :placeholder="$t('main.enterName')"
                  :label="$t('main.name')"
                  v-model="branch.name"
                  name="name"
                  validate="required"
                ></input-form>
              </b-col>
              <b-col md="12" class="mb-3">
                <input-form
                  :placeholder="$t('main.address')"
                  :label="$t('main.address')"
                  v-model="branch.address"
                  name="address"
                  validate="required"
                ></input-form>
              </b-col>
              <b-col md="4" class="mb-3">
                <main-select
                  :reduce="(city) => city.id"
                  :options="allCities"
                  v-model="city_id"
                  label="name"
                  class="flex-grow-1 mb-0 w-100"
                  :placeholder="$t('main.city')"
                  dir="rtl"
                  validate="required"
                />
              </b-col>
              <b-col md="4" class="mb-3">
                <main-select
                  :reduce="(area) => area.id"
                  :options="allAreas"
                  v-model="area_id"
                  label="name"
                  class="flex-grow-1 mb-0 w-100"
                  :placeholder="$t('main.area')"
                  dir="rtl"
                  validate="required"
                />
              </b-col>
            </b-row>
            <!-- <workingHours /> -->
          </b-card>
        </div>
        <div class="form-actions">
          <b-button variant="outline-primary" @click="$router.back()">
            {{ $t("main.back") }}
          </b-button>
          <b-button
            v-if="$route.name !== 'showProduct'"
            variant="primary"
            type="submit"
            :disabled="loadingSubmit"
          >
            <span v-if="!loadingSubmit">
              {{ $t("main.save") }}
            </span>
            <spinner-loading v-else text="loading"></spinner-loading>
          </b-button>
        </div>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
import branchServices from "@/modules/branches/services/branches";
import citiesAndCountriesMixin from "@/mixins/countriesAndCities";
export default {
  mixins: [citiesAndCountriesMixin],
  mounted() {
    core.index();
  },
  data() {
    return {
      branch: {
        name: "",
        address: "",
        country: "",
        city_id: "",
        area_id: "",
      },

      loadingSubmit: false,
      branchData: [],
    };
  },
  methods: {
    getBranch() {
      branchServices.getBranch(this.$route.params.id).then((res) => {
        this.branch = res.data.data;
        this.city_id = res.data.data.city.id;
        this.$nextTick(() => {
          this.area_id = res.data.data.area.id;
        });
      });
    },

    onSubmit() {
      this.loadingButtonSubmit = true;
      if (this.$route.params.id) {
        branchServices
          .editBranch(this.$route.params.id, this.branch)
          .then((res) => {
            core.showSnackbar("success", res.data.message);
            this.$router.push({ name: "branches" });
          })
          .catch((err) => {
            core.showSnackbar("error", err.response.data.message);
          })
          .finally(() => {
            this.loadingButtonSubmit = false;
          });
      } else {
        branchServices
          .addBranch(this.branch)
          .then((res) => {
            core.showSnackbar("success", res.data.message);
            this.$router.push({ name: "branches" });
          })
          .catch((err) => {
            core.showSnackbar("error", err.response.data.message);
          })
          .finally(() => {
            this.loadingButtonSubmit = false;
          });
      }
    },
  },
  watch: {
    city_id(v) {
      this.branch.city_id = v;
    },
    area_id(v) {
      this.branch.area_id = v;
    },
  },
  created() {
    if (this.$route.params.id) {
      this.getBranch();
    }
  },
};
</script>
<style lang="scss">
.input-with-icon-container {
  background-color: #fff;
  height: 45px;
  border: 1px solid #d7dbda;
  .input-with-icon {
    height: 100%;
    background: transparent !important;
    flex: 1;
    padding-inline-start: 20px !important;
    &.border-0 {
      border: none !important;
    }
  }
  .icon {
    min-width: 40px;
    height: 100%;
    font-size: 23px;
  }
}
</style>
