import mainServices from '@/services/main';
export default {
  data () {
    return {
      allCities: [],
      allCountries: [],
      allAreas: [],
      city_id: null,
      area_id: null,
      // insuranceCompanies: [],
      allStatus: [ 'سارية', 'مغلقة' ]

    };
  },
  methods: {
    getAllCities () {
      mainServices.getAllCities().then(res => {
        this.allCities = [ { name: 'الكل', id: '' }, ...res.data.data ];
      });
    },

    getAllAreas (city_id) {
      this.area_id = null;
      mainServices.getAllAreas(city_id).then(res => {
        this.allAreas = res.data.data;
      });
    }

  },

  created () {
    this.getAllCities();
  },
  watch: {
    city_id (val) {
      this.getAllAreas(val);
    }
  }
};
